import { h, render } from 'preact';
import { StepCounter } from './questionNumber.component';
import { ViewHeadline } from './viewHeadline.component';
import { useState, useContext, useEffect } from 'preact/hooks';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../GlobalState';
import { queryStringParser } from '../utility/queryStringParser.utility';
import { formObserver } from '../utility/formObserver.utility';

export function AccidentType() {
  const { state, updateModelProperty } = useContext(GlobalContext);
  const [accidentType, setAccidentType] = useState('');
  const queryParamsObj = queryStringParser(window.location.search, state)

  const CURRENT_STEP = 1;
  const TOTAL_STEPS = 11;

  const pageHeader = {
    headline: 'How did you get hurt?'
  }
  const headlineChild = <ViewHeadline {...pageHeader} />

  const progressChild = (
    <StepCounter
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS} />
  );

  const nextUrl = 'incident-date';

  const onClickHandler = (state, callback) => (ev) => {
    callback(state);
  }

  const onSelectChange = (ev) => {
    setAccidentType(ev.target.value);
  }

  const updateModel = (data) => {
    updateModelProperty(data);
  }

  const button = {
    to: nextUrl,
    modelProp: 'accidentType',
    buttonText: 'Start Evaluation'
  };

  useEffect(() => {
    formObserver(updateModelProperty);

    updateModelProperty({
      ...queryParamsObj
    });
  }, []);

  return (
    <div className="view-bg start">
      <CSSTransition
        in={true}
        appear
        enter
        timeout={0}
        classNames={'flow-view'}>

        <div className="col-12 col-sm-10 offset-sm-1">
          <div className="view-header">
            <h2 className={'text-center mb-4'}>We will fight to get you MAXIMUM COMPENSATION from your Motor Vehicle Accident!</h2>
            <h3 className={'text-center'}>Were you injured in an accident through no fault of your own?</h3>
          </div>

          <form id={'accident-type'} action="" className={'mb-4'}>
            <select className={'form-select  form-select-lg'} name="accidentType" id="accidentType" onChange={onSelectChange}>
              <option value="" selected>Select One</option>
              <option value="mva">Car Accident</option>
              <option value="semi">18 Wheeler (Semi) Accident</option>
              <option value="motorcycle">Motorcycle Accident</option>
              <option value="work">Work Accident</option>
              <option value="other">Other Accident</option>
            </select>
          </form>

          <div className="next-btn-wrap col-12 col-sm-8 offset-sm-2 text-center text-center">
            <Link {...button}
                className={'flow-btn btn btn-outline-dark btn-default'}
                onClick={onClickHandler({ accidentType }, updateModel)}>Start Your Evaluation</Link>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}
